import { GetStaticProps } from "next";
import dynamic from 'next/dynamic';
import { CodeArena } from "@/components/event/types";
import { Story2 } from '@/components/MainArea/StoryArea';
// import LandingBanner from "@/components/Banners/LandingBanner";
// import ArenaArea from '@/components/event/CarouselCard';

export const Loader = () => {
  return (
    <div className="loader center" />
  )
}

const Page = dynamic(() => import('@/layout/Page'), { ssr: false });
const LandingBanner = dynamic(() => import('@/components/Banners/LandingBanner'), { loading: () => <Loader />, ssr: false });
const ArenaArea = dynamic(() => import('@/components/event/CarouselCard'), { loading: () => <Loader />, ssr: false });
const Glance = dynamic(() => import('@/components/MainArea/Glance'), { loading: () => <Loader />, ssr: false });
const Brands = dynamic(() => import('@/components/MainArea/Brands'), { loading: () => <Loader />, ssr: false });
const StoryArea = dynamic(() => import('@/components/MainArea/StoryArea'), { loading: () => <Loader />, ssr: false });
const NewsArea = dynamic(() => import('@/components/MainArea/NewsArea'), { loading: () => <Loader />, ssr: false });
interface Props {
  arenasOld: CodeArena[];
  arenasNew: CodeArena[];
  arena: CodeArena;
}

export default function LandingPage({ arenasOld, arenasNew, arena }: Props) {

  return (
    <Page>
      <LandingBanner />
      <div className="container">
        {arenasNew && arenasNew.length > 0 && <ArenaArea Data={arenasNew} data={arena} Title={"Explore CodeArenas"} />}
        {arenasOld && arenasOld.length > 0 && <ArenaArea Data={arenasOld} data={arena} Title={"Past CodeArenas"} />}
      </div>
      <Glance />
      <Brands />
      <NewsArea />
      <StoryArea story={Story2} title="What Our Customers Say" />
    </Page >
  );
}


export const getStaticProps: GetStaticProps = async () => {
  const [upcoming, prev] = await Promise.all([
    fetch(
      process.env.NEXT_PUBLIC_API_URL + "/fest/getUpcomingFests",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        }
      }
    ),
    fetch(
      process.env.NEXT_PUBLIC_API_URL + "/fest/getPreviousFests",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        }
      }
    ),
  ])
  if (upcoming.status == 401) {
    return {
      redirect: {
        permanent: false,
        destination: "/logout",
      }
    }
  }
  const arenasOld = await prev.json();
  const arenasNew = await upcoming.json();
  // console.log(arenasOld, arenasNew);
  // const session = await getSession(context);
  // if (!!session) {
  //   const user: DefaultUser = session.user as DefaultUser;
  //   return {arenasOld, arenasNew, user};
  // }
  return {
    props: { arenasOld, arenasNew },
    revalidate: 600
  };
};