import Image from "next/image";

interface Story {
    name: string,
    designation: string,
    story: string,
    imageUrl: string,
    liked: boolean,
    tech?: string[];
}

interface StoryAreaProps {
    story: Story[]
    title: string
}

// Whole StoryArea
const StoryArea = ({ story, title }: StoryAreaProps) => {
    return (
        <div className="py10 bg-black">
            <p className="base31 wt-bold gradient-heading center mb5">{title}</p>
            <div className="container card bluebg py9">
                {/* <div className="spacing4 justify-center text-center grid grid-col-xs12 grid-col-md6">
                    {story.map((story) => {
                        return (
                            <div key={story.name}>
                                {story.designation}
                            </div>
                        );
                    })}
                </div> */}
                <div className="spacing2 flex nowrap">
                    {story.map((story) => {
                        return (
                            <div key={story.name} className="first-and-last xs11 md5 blue-card p2 flex column spacing1">
                                <div style={{ border: '2px solid rgba(255, 255, 255, 0.4)', borderRadius: "50%", overflow: "hidden", height: "50px", width: "50px" }}>
                                    <Image
                                        src={story.imageUrl}
                                        alt="company logo"
                                        height={48}
                                        width={48}
                                    />
                                </div>
                                <p className="white">{story.name}</p>
                                <p className="cyber-green-primary base13">{story.designation}</p>
                                {story.tech && <div className="spacing1 flex">
                                    {story.tech!.map((tech) => {
                                        return (
                                            <div key={tech} className="border-las-vegas">
                                                <p key={tech} className="base13" style={{ padding: "4px" }}>{tech}</p>
                                            </div>
                                        )
                                    })}
                                </div>}
                                <p style={{ lineHeight: "24px" }} className="text-purple wt-light base13 clamp4 clamp">{story.story}</p>
                                <p className="base13">Read More</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
export default StoryArea;



// Dummy data
export const Story1: Story[] = [
    {
        name: "Vanshika Pandey",
        designation: "SDE intern at Dukaan and Incoming SDE intern at Morgan Stanley",
        story: "CometLabs helped me land my dream job. The CodeArenas and take-home assessments provided the perfect platform to showcase my skills. The support from the community was amazing, and I made some great connections along the way. If you're a developer looking for a community to grow and thrive in, join CometLabs!",
        imageUrl: "/stories/vanshika.jfif",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Ishika Sinha",
        designation: "Mentee at Codess.cafe, intern at IITK",
        story: "I can't say enough good things about CometLabs. The events and workshops are engaging and provide an excellent opportunity to connect with other developers. The community is incredibly supportive, and the platform provided me with invaluable insights to improve my skills. Highly recommended! ",
        imageUrl: "/stories/ishika.jfif",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Koneru Rehasree",
        designation: "Software Engineer Intern at Supplys, Freelancer",
        story: "CometLabs is the perfect platform for developers looking to grow their skills and connect with other like-minded individuals. The events and workshops are top-notch, and the community is incredibly supportive. I've learned so much and landed some fantastic opportunities thanks to CometLabs!",
        imageUrl: "/stories/reha.jfif",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Omkar Deshmukh",
        designation: "Incoming SWE intern at Google, ACM ICPC Regionalist",
        story: "Thanks to CometLabs, I landed a job I love. The take-home assessments and hackathons provided the perfect platform to showcase my skills and get noticed by recruiters. The community is top-notch, and the platform provides an amazing range of resources for developers. Highly recommend!",
        imageUrl: "/stories/omkar.jfif",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Manish Kumar",
        designation: "Infrastructure Engineer at Blaze AI (YC W22)",
        story: "CometLabs is a game-changer for developers looking to advance their careers. The platform provides an amazing range of resources, including take-home assessments, CodeArenas, and product-building bootcamps. The community is super-engaging and provides a great opportunity to connect with others in the field. Highly recommended!",
        imageUrl: "/stories/manish.jfif",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Vibhanshu Jain",
        designation: "SWE intern at StoryXpress",
        story: "CometLabs is the ultimate community for developers looking to connect with others and grow their skills. The events and workshops are engaging, and the platform provides invaluable insights into coding best practices. I landed an amazing job thanks to CometLabs and have made some fantastic connections along the way. Highly recommend!",
        imageUrl: "/stories/vibhanshu.jfif",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
];

export const Story2: Story[] = [
    {
        name: "Hemalatha Kanneganti",
        designation: "Co-founder & CEO of Grabbit Live",
        story: "We were struggling to find a platform that allowed us to assess candidates' technical abilities in specific technologies like React and Angular. CometLabs provided us with a one-stop solution that allowed us to schedule technology-specific interviews and evaluate candidates' skills through take-home assignments. The platform is intuitive, and the integrated video calling and question bank made the interviewing process streamlined and effortless. Highly recommend CometLabs for any startup or enterprise looking to hire top tech talent.",
        imageUrl: "/stories/Hemalatha.jpeg",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Harleen Kaur",
        designation: "Partner at Sterlcent",
        story: "CometLabs has revolutionized our hiring process. As a startup, we were struggling to find the right talent for our technical roles, and the screening process was taking up so much time. But with CometLabs, we were able to assess the skills and abilities of candidates efficiently and find the perfect match for our team. The platform is easy to use, and the in-browser IDEs provide deep insights into code quality and best practices. Thank you, CometLabs! ",
        imageUrl: "/stories/Harleen.jpeg",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Rajat Dangi",
        designation: "Co-founder of Blinkstore",
        story: "CometLabs has made our hiring process a breeze. We were able to find the right developer for our team within a few days of using the platform. The in-browser IDEs and machine learning algorithms allowed us to evaluate the coding abilities and best practices of candidates accurately. The platform's reporting and collaboration features also made it easier to share feedback and make informed hiring decisions. We couldn't be happier with the results!",
        imageUrl: "/stories/Rajat.jpeg",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
    {
        name: "Nikhil Vyas",
        designation: "Co-founder of Vysion Technologies",
        story: "As a startup, we needed a cost-effective solution for hiring top tech talent. CometLabs provided us with a subscription-based service that allowed us to access in-browser IDE Playgrounds and schedule technology-specific interviews in popular frameworks like NextJS and ReactJS. The take-home assessments also allowed us to evaluate candidates' skills and abilities accurately. CometLabs has streamlined our hiring process and saved us time and money. Highly recommend!",
        imageUrl: "/stories/Nikhil.jpeg",
        tech: ["PHP", "JAVA"],
        liked: false,
    },
];